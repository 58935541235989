import { Box, FormLabel, Grid, Radio } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import React, { useEffect, useState } from "react";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import dayjs from "dayjs";
import FeDesktopDatePicker from "../../../FormElements/FeDesktopDatePicker";
import FeTextArea from "../../../FormElements/FeTextArea";
import { FeAutocomplete, FeTextField } from "../../../FormElements";
import { updateObjectValue } from "../../../../utils/helper";

const ChemoProtoDateCycle = ({ onStateChange, editedObj, updatedObj }) => {
  const [obj, setObj] = useState({
    current_chemo_protocol: "",
    chemo_starting_date: null,
    chemo_cycle_no: null,
    line_of_treatment: "",
  });
  useEffect(() => {
    if (editedObj) {
      const u = updateObjectValue(obj, editedObj);
      setTimeout(() => {
        setObj(u);
      }, 105);
    }
  }, [editedObj]);
  useEffect(() => {
    if (updatedObj) {
      const u = updateObjectValue(obj, updatedObj);
      setTimeout(() => {
        setObj(u);
      }, 100);
    }
  }, [updatedObj]);
  const handleChangeInput = (name, value) => {
    setObj({ ...obj, [name]: value });
  };
  useEffect(() => {
    onStateChange(obj);
  }, [obj, onStateChange]);
  return (
    <>
      <Grid item xs={6} md={4}>
        <Box mb={2}>
          <FeTextArea
            //  disabled={editedObj}
            id="CurrentChemoProtocol"
            label="Current Chemo Protocol"
            onChange={(e) =>
              handleChangeInput("current_chemo_protocol", e.target.value)
            }
            defaultValue={obj.current_chemo_protocol}
          />
        </Box>
      </Grid>

      <Grid item xs={6} md={4}>
        <Box
          mb={2}
          sx={{
            "& .MuiFormControl-root": {
              width: "100%",
            },
          }}
        >
          <label className="form-label">Date of Starting</label>

          <FeDesktopDatePicker
            //  disabled={editedObj}
            // label="Date of Starting"
            inputFormat="DD/MM/YYYY"
            value={obj.chemo_starting_date}
            onChange={(val) => {
              handleChangeInput(
                "chemo_starting_date",
                dayjs(val).format("YYYY-MM-DD")
              );
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        <Box mb={2}>
          <FeAutocomplete
            options={[
              "1st Line",
              "Continuous Maintenance",
              "Switch Maintenance",
              "2nd Line",
              "3rd Line",
              "4th Line",
              "5th Line",
            ]}
            id="LOT"
            label="Line of Treatment (LOT)"
            //  disabled={editedObj}
            value={obj.line_of_treatment}
            onChange={(e, v) => {
              handleChangeInput("line_of_treatment", v);
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        <Box mb={2}>
          <FeTextField
            type="number"
            label="Cycle No"
            value={obj.chemo_cycle_no}
            onChange={(e) => {
              setObj({ ...obj, chemo_cycle_no: e.target.value });
            }}
          />
        </Box>
      </Grid>
    </>
  );
};

export default ChemoProtoDateCycle;
